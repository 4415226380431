import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { IDarkTheme } from "styles/darkTheme";

export const styles = makeStyles((theme: IDarkTheme) =>
  createStyles({
    navContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "20px",
      position: "fixed",
      top: 0,
      width: "100%",
      background: theme.colors.semantic.semantic_white,
      zIndex: 400,
      "@media (max-width: 1100px)": {
        padding: "24px 44px 10px 24px",
        "& > div:last-of-type": { display: "none" }
      }
    },
    logoDrawerContainer: {
      width: 311,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: 311,
        boxSizing: "border-box",
        borderRadius: "0px 32px 32px 0px"
      },
      "& > div > div > div:first-of-type": {
        paddingBottom: "16px",
        paddingTop: "32px",
        "& > img": {
          marginBottom: "32px",
          marginLeft: "16px"
        },
        "& > div:first-of-type": {
          marginLeft: "16px"
        }
      }
    },
    logoContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > svg": {
        display: "none"
      },
      "@media (max-width: 1100px)": {
        "& > svg": {
          cursor: "pointer",
          display: "block",
          marginRight: "11px"
        }
      }
    },
    mainLogo: {
      "@media (max-width: 1000px)": {
        justifySelf: "flex-start",
        flexGrow: 4
      },
      "@media (max-width: 700px)": {
        justifySelf: "flex-start",
        flexGrow: 4,
        margin: "0px"
      }
    },
    pageLinksContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 1000px)": {
        display: "none"
      }
    },
    pageLinks: {
      listStyleType: "none",
      display: "inline-block",
      color: theme.colors.semantic.semantic_grey,
      textDecoration: "none",
      "@media (max-width: 1160px)": {
        marginRight: "24px"
      }
    },
    links: {
      fontSize: theme.fontSize.bodyText_1,
      fontFamily: theme.fontFamily.montserrat,
      opacity: "80%",
      fontWeight: theme.fontWeight.regular,
      display: "inline-block",
      marginRight: "48px"
    },
    linkSelected: {
      display: "inline",
      color: theme.colors.secondary.secondary_50,
      fontSize: theme.fontSize.bodyText_1,
      fontFamily: theme.fontFamily.montserrat,
      opacity: "100%",
      fontWeight: theme.fontWeight.bold,
      position: "relative",
      textAlign: "center",
      verticalAlign: "top",
      "&::before": {
        transform: "translateX(-50%)",
        borderRadius: "100%",
        position: "absolute",
        background: theme.colors.secondary.secondary_50,
        bottom: "10px",
        height: "8px",
        content: '" "',
        width: "8px",
        left: "50%",
        top: "20px",
        textAlign: "center"
      }
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    userButton: {
      position: "fixed",
      left: "15%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "187px",
      height: "48px",
      padding: "8px 24px 8px 24px",
      "@media (max-width: 1100px)": {
        paddingRight: "34px"
      },
      borderRadius: "8px",
      border: "none",
      cursor: "pointer"
    },
    userImage: {
      width: "45px",
      height: "45px",
      padding: "0px",
      margin: "0px",
      backgroundSize: "cover",
      borderRadius: "50%"
    },
    userName: {
      fontWeight: theme.fontWeight.big
    },

    dot: {
      height: "5px",
      width: "5px",
      backgroundColor: theme.colors.semantic.semantic_grey,
      borderRadius: "50%",
      display: "inline-block"
    },
    textButton: {
      fontFamily: theme.fontFamily.montserrat,
      fontWeight: theme.fontWeight.medium,
      fontSize: theme.fontSize.bodyText_1,
      color: theme.colors.semantic.semantic_grey,
      margin: "0 8px",
      display: "flex",
      flexDirection: "column"
    },
    angleDownIcon: {
      color: theme.colors.semantic.semantic_grey,
      fontFamily: theme.fontFamily.montserrat,
      fontSize: theme.fontSize.bodyText_1,
      fontWeight: theme.fontWeight.medium
    },
    logoutButton: {
      background:
        "linear-gradient(88.87deg, #6813BD 0%, #9208C8 100%)",
      borderRadius: "8px",
      padding: "8px 40px",
      border: "none",
      marginBottom: "20px",
      fontFamily: theme.fontFamily.montserrat,
      fontSize: theme.fontSize.bodyText_2,
      fontWeight: theme.fontWeight.light,
      color: theme.colors.semantic.semantic_grey,
      cursor: "pointer"
    },
    burgerButton: {
      display: "none",
      "@media (max-width: 1000px)": {
        display: "inline-block",
        justifySelf: "flex-end",
        width: "40px",
        height: "40px",
        color: theme.colors.semantic.semantic_grey,
        cursor: "pointer",
        alignSelf: "flex-end"
      }
    },
    burgerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    burgerUserContainer: {
      display: "flex",
      margin: "5px",
      gap: "5px"
    },
    burgerChildContainer: {
      background: "rgba(255, 255, 255, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "5px",
      borderRadius: "8px",
      marginRight: "10px"
    },
    burgerLinks: {
      textDecoration: "none",
      color: theme.colors.semantic.semantic_grey,
      marginBottom: "20px"
    },
    leftSection: {
      display: "flex",
      "@media (max-width: 1100px)": {
        width: "100%",
        justifyContent: "space-between",
        "& > a:last-of-type > div:first-of-type": {
          position: "unset",
          justifyContent: "end",
          "& > p": { display: "none" }
        }
      }
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    },
    title: {
      fontWeight: theme.fontWeight.big,
      fontSize: "14px",
      lineHeight: "17px"
    },
    position: {
      fontWeight: theme.fontWeight.medium,
      color: "#9A9A9A",
      fontSize: "16px",
      lineHeight: "20px"
    },
    userScore: {
      position: "absolute",
      bottom: "-8px",
      left: "-10px",
      padding: "6px",
      zIndex: "2"
    },
    drawerContainer: {
      width: "100%",
      height: "100%",
      "& > div": { display: "block" }
    },
    scoreChip: {
      height: "17px",
      width: "41px",
      background: theme.colors.semantic.semantic_yellow,
      border: "2px solid white",
      fontWeight: theme.fontWeight.bold,
      fontSize: "9px",
      lineHeight: "12px",
      "& > span": {
        paddingInline: "4px"
      }
    }
  })
);
