import { createContext, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import LoadingAnimation from "components/SharedComponents/LoadingAnimation";
import { darkBackground } from "./darkBackground";
import AppRoutes from "./routes";
export const SearchContext = createContext({
  searchTerm: "",
  setSearchTerm: (searchTerm: string) => {}
});
const AppRouter = () => {
  const classes = darkBackground();

  return (
    <div className={classes.mainContainer}>
      <Suspense fallback={<LoadingAnimation isLoading={true} />}>
        <Router>
          <AppRoutes />
        </Router>
      </Suspense>
    </div>
  );
};

export default AppRouter;
