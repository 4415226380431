import { useState } from "react";
import { Chip, Skeleton, SvgIcon, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import Searchbar from "components/newFeeds/searchBar";
import RightSplash from "assets/right-splash.svg";
import LeftSplash from "assets/left-splash.svg";
import { useCurrentUser } from "app/appHooks";
import { PATH_NAME } from "routes/pathNames";
import AppLogo from "assets/main-logo.svg";
import { SearchContext } from "routes";
import { DrawerModal } from "./drawer";
import { styles } from "./styles";
import trackEvent from "utils/useAnalyticsEventTracker";

interface INavBar {
  navBarTitle: string;
  style: object;
  showBanner: boolean;
}

const NavBar = ({ navBarTitle, style, showBanner }: INavBar) => {
  const classes = styles();
  const { data: user, isLoading } = useCurrentUser();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen:
    | React.MouseEventHandler<SVGSVGElement>
    | undefined = (e) => {
    e?.preventDefault();
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleUserClick = () => {
    trackEvent("topmenu_account_btn_clicked", {
      type: "user_click"
    });
  };

  return (
    <SearchContext.Consumer>
      {({ setSearchTerm, searchTerm }) => (
        <>
          <Box
            component="nav"
            className={classes.navContainer}
            style={style}
          >
            <Box className={classes.leftSection}>
              <Link
                to={PATH_NAME.LANDING_PAGE}
                className={classes.logoContainer}
              >
                <SvgIcon
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: "none" }) }}
                  component={MenuIcon}
                  inheritViewBox
                />
                <Box
                  component="img"
                  className={classes.mainLogo}
                  src={AppLogo}
                  alt="Hoya App"
                />
              </Link>

              <Link
                to={`${PATH_NAME.ACCOUNT}/${user?.id}`}
                className={classes.buttonContainer}
                onClick={handleUserClick}
              >
                <Box className={classes.userButton}>
                  <Box position="relative">
                    <Box
                      component="img"
                      src={user?.image48}
                      alt=""
                      className={classes.userImage}
                    />
                    <Stack
                      direction="row"
                      className={classes.userScore}
                    >
                      <Box
                        component="img"
                        src={LeftSplash}
                        alt="left-arrow"
                      />
                      <Chip
                        label={
                          isLoading ? (
                            <Skeleton variant="text" width="1rem" />
                          ) : (
                            `${
                              user &&
                              user?.plusCount - user?.minusCount
                            } pt`
                          )
                        }
                        variant="outlined"
                        className={classes.scoreChip}
                      />
                      <Box
                        component="img"
                        src={RightSplash}
                        alt="right-arrow"
                      />
                    </Stack>
                  </Box>
                  <Typography className={classes.textButton}>
                    <Typography className={classes.userName}>
                      {user?.displayName || user?.firstName}
                    </Typography>
                    <Box className={classes.userInfo}>
                      <Typography className={classes.title}>
                        {user?.title}
                      </Typography>
                      <Typography className={classes.position}>
                        {user?.title}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Box>
              <Searchbar
                searchValue={searchTerm}
                setSearchValue={setSearchTerm}
              />
            </Box>
          </Box>
          <DrawerModal
            showBanner={showBanner}
            open={open}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleDrawerClose={handleDrawerClose}
          />
        </>
      )}
    </SearchContext.Consumer>
  );
};

export default NavBar;
