// Service to check authentication for user and to signOut
const Auth = {
  signOut() {
    localStorage.removeItem("token");
  },
  isAuth() {
    return localStorage.getItem("token");
  },
  userAccessToken(){
    return localStorage.getItem("token");
  },
  accessToken() {
    const token = localStorage.getItem("token");
    const adminToken = localStorage.getItem("admin-token");
    return adminToken || token;
  },
  superAdminAccessToken () {
    return localStorage.getItem("admin-token");
  }
};
export default Auth;
