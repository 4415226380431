import LoadingAnimation from "components/SharedComponents/LoadingAnimation";
import NavBar from "components/SharedComponents/navBar";
import Sidebar from "components/SharedComponents/SideBar";
import { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import { SearchContext } from "routes";
import { darkBackground } from "routes/darkBackground";
import { styles } from "./styles";
// import { useCurrentSubscription } from "pages/settings/subscriptionView/hooks";

const AppLayout = () => {
  const classes = darkBackground();
  const layoutClasses = styles();
  const [searchTerm, setSearchTerm] = useState("");

  // TODO: Uncomment this if you need the payment back
  // const { data: currentSub, isLoading: isSubscribedLoading } =
  //   useCurrentSubscription();
  // const showBanner =
  //   (!currentSub?.subscribed as boolean) && !isSubscribedLoading;
  const showBanner = false;
  return (
    <div className={classes.mainContent}>
      <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
        <div
          className={layoutClasses.navContainer}
          style={{ height: showBanner ? "140px" : "90px" }}
        >
          {/** TODO: Uncomment this if you need the payment back */}
          {/* {showBanner && (
            <div className={layoutClasses.trialBanner}>
              {currentSub &&
              currentSub.plusPlusCount < currentSub.plusPlusTrialLimit
                ? `You've reached ${currentSub.plusPlusCount}++. Please subscribe before reaching ${currentSub.plusPlusTrialLimit}++ to continue using all our features.`
                : "You're subscription has ended. Please subscribe to continue using all our features."}
            </div>
          )} */}
          <NavBar
            showBanner={showBanner}
            navBarTitle="Hoya App"
            style={{ top: showBanner ? "50px" : "0px" }}
          />
        </div>
        <div className={classes.contentWrapper}>
          <Sidebar showBanner={showBanner} />
          <main className={layoutClasses.contentWrapper}>
            <div className={layoutClasses.appContent}>
              <Suspense
                fallback={<LoadingAnimation isLoading={true} />}
              >
                <Outlet />
              </Suspense>
            </div>
          </main>
        </div>
      </SearchContext.Provider>
    </div>
  );
};

export default AppLayout;
