import AppRouter from "routes";
import { IntercomProvider } from "react-use-intercom";

const App = () => {
  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM_ID || ""}
      autoBoot
    >
      <AppRouter />
    </IntercomProvider>
  );
};

export default App;
