import { APIS } from "utils/constants";
import { axiosInstance } from "./index";
import { UserID } from "./users";

type IAuthURLS = {
  slackAddtoURL: string;
  slackSigninURL: string;
};
export const getAuthUrls = async (): Promise<IAuthURLS> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(APIS.AUTH.SETTINGS)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
type ISignInResponse = {
  token: string;
  user: {
    id: UserID;
    displayName: string;
    email: string;
    externalId: string;
    firstName: string;
    lastName: string;
    image48: string;
    image512: string;
    phone: string;
    title: string;
    integrationType: string;
    memberId: number;
    minusCount: number;
    plusCount: number;
    teamId: number;
  };
};
export const authorizeSlack = async (
  code: string,
  state: string
): Promise<ISignInResponse> => {
  const url = state.startsWith("add_to_slack")
    ? APIS.AUTH.ADD_TO_WORK_SPACE
    : APIS.AUTH.SIGN_IN;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${url}?code=${code}&state=${state}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
