import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IDarkTheme } from "styles/darkTheme";

export const styles = makeStyles((theme: IDarkTheme) =>
  createStyles({
    contentWrapper: {
      maxWidth: "calc(100vw - 244px)",
      position: "relative",
      left: "244px",
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      overflowX: "hidden",
      height: "100%",
      "@media (max-width: 1100px)": {
        marginLeft: "0px",
        width: "100%",
        left: "unset",
        maxWidth: "unset"
      },
      "@media (min-width: 1700px)": {
        margin: "0 auto",
        maxWidth: "1434px",
        left: "122px"
      }
    },
    navContainer: {
      width: "100%"
    },
    trialBanner: {
      display: "flex",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      width: "100%",
      backgroundColor: "#FF4154",
      color: "white",
      fontWeight: 600,
      zIndex: 400
    },
    appContent: {
      width: "calc(100% - 244px)",
      minHeight: "100vh",
      flex: 1,
      margin: "0 auto",
      maxWidth: "1100px",
      borderTopLeftRadius: "40px",
      backgroundColor: theme.colors.neutrals.neutrals_20,
      padding: "24px 0px 24px 0",
      "@media (max-width: 750px)": {
        padding: "0px 0px"
      }
    }
  })
);
