import { useQuery } from "react-query";
import { authorizeSlack } from "../network/apis/auth";
import { currentUser } from "../network/apis/users";
import { useQueryClient } from "react-query";
import { QUERIES } from "../utils/constants";
import { PATH_NAME } from "routes/pathNames";

export const useAuthorizeSlack = (
  setTokenExist: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const state = params.get("state");
  const queryClient = useQueryClient();
  return useQuery(
    [QUERIES.SLACK_AUTH, code, state],
    () => authorizeSlack(code || "", state || ""),
    {
      onSuccess: (data) => {
        localStorage.setItem("token", data?.token);
        queryClient.setQueryData(
          QUERIES.LOAD_CURRENT_USER,
          data?.user
        );
        setTokenExist(true);
      },
      onError: (error: any) => {
        queryClient.setQueryData([QUERIES.SLACK_AUTH, null, null], {
          error: error.response.data
        });
      },
      onSettled: () => {
        window.history.replaceState(
          {},
          document.title,
          PATH_NAME.HOME_PAGE
        );
      },
      enabled: !!code && !!state,
      retry: false,
      staleTime: Infinity,
      suspense: true
    }
  );
};

export const useCurrentUser = (enabled?: any, suspense?: boolean) => {
  return useQuery(QUERIES.LOAD_CURRENT_USER, currentUser, {
    enabled: enabled,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    suspense: suspense,
    useErrorBoundary: false
  });
};
