import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { IDarkTheme } from "styles/darkTheme";

export const darkBackground = makeStyles((theme: IDarkTheme) =>
  createStyles({
    mainContainer: {
      height: "100%"
    },
    mainContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column"
    },
    contentWrapper: {
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      flexGrow: 1
    }
  })
);
