import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IDarkTheme } from "styles/darkTheme";

const styles = makeStyles((theme: IDarkTheme) =>
  createStyles({
    searchIcon: {
      width: "18.97px",
      height: "18.99px",
      color: theme.colors.secondary.secondary_50
    },
    searchIconContainer: {
      width: "43.92px",
      height: "43.92px",
      background: theme.colors.secondary.secondary_60,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      cursor: "pointer",
      marginInlineStart: "10px",
      "@media (max-width: 900px)": {
        marginRight: "10px"
      }
    },
    closeIconContainer: {
      width: "43.92px",
      height: "43.92px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    searchBarContainer: {
      width: "535px",
      minHeight: "43.92px",
      display: "flex",
      background: theme.colors.secondary.secondary_60,
      borderRadius: "8px",
      alignItems: "center",
      "@media (max-width: 900px)": {
        width: "200px"
      },
      "@media (max-width: 420px)": {
        width: "160px"
      }
    },
    searchBar: {
      minHeight: "43.92px",
      minWidth: "440px",
      background: "transparent",
      border: "none",
      color: theme.colors.secondary.secondary_50,
      borderRadius: "8px",
      padding: "12px",
      "&::placeholder": {
        color: theme.colors.secondary.secondary_50,
        fontWeight: theme.fontWeight.light,
        fontSize: theme.fontSize.bodyText_2
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none"
      },
      "@media (max-width: 900px)": {
        minWidth: "100px"
      }
    }
  })
);

export default styles;
