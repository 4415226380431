export const PATH_NAME = Object.freeze({
  // Private routes
  LEADERBOARD: "/app/leaderboard",
  ACCOUNT: "/app/account",
  NEWS_FEED: "/app/newsfeed",
  HOME_PAGE: "/app",
  SETTINGS: "/app/settings",
  LOGIN: "/login",
  INSIGHTS: "/app/insights",
  PUBLIC_PROFILE: "/public_profile",
  HELP_CENTER: "/help_center",
  ADMIN: "/app/admin",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-service",
  LANDING_PAGE: "/"
});
