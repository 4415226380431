import ReactDOM from "react-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
  CssBaseline,
  adaptV4Theme
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import darkTheme from "styles/darkTheme";
import App from "./app";
import "index.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const queryClient = new QueryClient();

const defaultTheme = createTheme(adaptV4Theme({}));

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={{ ...defaultTheme, ...darkTheme }}>
        <CssBaseline />
        <ToastContainer />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);
