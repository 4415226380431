import { useEffect, useRef, useState } from "react";
import styles from "./styles";
import { FiSearch, FiX } from "react-icons/fi";
import Fade from "@mui/material/Fade";
import { PATH_NAME } from "routes/pathNames";
import { useLocation, useNavigate } from "react-router-dom";
import trackEvent from "utils/useAnalyticsEventTracker";

interface ISearchBar {
  setSearchValue: (arg0: string) => void;
  searchValue: string;
}

// use onRequestSearch={} for search request
const SearchBar = ({ setSearchValue, searchValue }: ISearchBar) => {
  const classes = styles();
  const searchTimeout: any = useRef(0);
  const location = useLocation();
  const history = useNavigate();
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearchIcon = () => {
    trackEvent("topmenu_search_clicked", {
      type: "user_click",
      screenname: "search"
    });

    setShowSearchBar(true);
  };

  const handleCloseIcon = () => {
    setSearchTerm("");
    setSearchValue("");
    setShowSearchBar(false);
  };

  useEffect(() => {
    if (!searchValue) handleCloseIcon();
    setSearchTerm(searchValue);
  }, [searchValue]);

  return (
    <>
      {showSearchBar ? (
        <Fade in={showSearchBar} unmountOnExit>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchIconContainer}>
              <FiSearch
                className={classes.searchIcon}
                onClick={handleSearchIcon}
              />
            </div>

            <input
              className={classes.searchBar}
              type="text"
              autoFocus
              placeholder="Search"
              value={searchTerm}
              onChange={(newValue: any) => {
                if (
                  location.pathname !== PATH_NAME.NEWS_FEED &&
                  location.pathname !== PATH_NAME.HOME_PAGE
                ) {
                  history(PATH_NAME.NEWS_FEED);
                }
                setSearchTerm(newValue.target.value);
                if (searchTimeout.current)
                  clearTimeout(searchTimeout.current);
                searchTimeout.current = setTimeout(() => {
                  setSearchValue(newValue.target.value);
                }, 1000);
              }}
            />

            <div className={classes.closeIconContainer}>
              <FiX
                className={classes.searchIcon}
                onClick={handleCloseIcon}
              />
            </div>
          </div>
        </Fade>
      ) : (
        <div className={classes.searchIconContainer}>
          <FiSearch
            className={classes.searchIcon}
            onClick={handleSearchIcon}
          />
        </div>
      )}
    </>
  );
};

export default SearchBar;
