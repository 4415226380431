import {
  ClickAwayListener,
  Drawer,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import Searchbar from "components/newFeeds/searchBar";
import AppLogo from "assets/main-logo.svg";
import { memo, useEffect } from "react";
import Sidebar from "../SideBar";
import { styles } from "./styles";

export const DrawerModal = memo(
  ({
    setSearchTerm,
    searchTerm,
    handleDrawerClose,
    open,
    showBanner
  }: {
    showBanner: boolean;
    open: boolean;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    handleDrawerClose: () => void;
  }) => {
    const classes = styles();
    const matches = useMediaQuery("(max-width: 1100px)");

    const handleSearchTerm = (searchTerm: string) => {
      setSearchTerm(searchTerm);
    };
    useEffect(() => {
      if (searchTerm && open && matches)
        window.setTimeout(() => handleDrawerClose(), 1500);
    }, [searchTerm, matches]);

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        open={open && matches}
        onClickAway={handleDrawerClose}
      >
        <Drawer
          className={classes.logoDrawerContainer}
          variant="persistent"
          anchor="left"
          open={open && matches}
        >
          <Box className={classes.drawerContainer}>
            <Box>
              <Box
                component="img"
                className={classes.mainLogo}
                src={AppLogo}
                alt="Hoya App"
              />
              <Box>
                <Searchbar
                  setSearchValue={handleSearchTerm}
                  searchValue={searchTerm}
                />
              </Box>
            </Box>
            <Sidebar
              handleItemClick={handleDrawerClose}
              showBanner={showBanner}
            />
          </Box>
        </Drawer>
      </ClickAwayListener>
    );
  }
);
