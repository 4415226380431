import { Dates_filters } from "./constants";
import Emoji from "react-emoji-render";
const dayjs = require("dayjs");

export function getTime(stringDate) {
  return dayjs(stringDate).format("hh:mm A");
}

export function getDividerDate(stringDate) {
  const fullDate = new Date(stringDate);
  if (fullDate.toDateString() === new Date().toDateString())
    return Dates_filters[1];
  return fullDate.toDateString();
}


export function getDateIgnoreTimezone(dateString) {
  return (
    new Date(
      new Date(dateString).getTime() +
        new Date(dateString).getTimezoneOffset() * 60 * 1000
    ).getTime() * 1000
  );
};

 //EndDate is exclusive at BE , so we use this function to add 1 more day to end date range
export function getEndDate(date) {
  return date.add(1, "day");

}

export function getStartEndDate(dateRange) {
  const dates = Dates_filters;
  let startDate = "",
    endDate = "";
  // Today
  if (dateRange === dates[1]) {
    startDate = dayjs().format("YYYY-MM-DD");
    endDate = dayjs().format("YYYY-MM-DD");
    // This Week
  } else if (dateRange === dates[2]) {
    // if today is sunday
    if (
      dayjs().startOf("week").format("YYYY-MM-DD") ===
      dayjs().format("YYYY-MM-DD")
    ) {
      startDate = dayjs()
        .startOf("week")
        .add(-6, "day")
        .format("YYYY-MM-DD");
    } else {
      startDate = dayjs()
        .startOf("week")
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    endDate = dayjs().format("YYYY-MM-DD");
    // This Month
  } else if (dateRange === dates[3]) {
    startDate = dayjs().startOf("month").format("YYYY-MM-DD");
    endDate = dayjs().format("YYYY-MM-DD");
    // Last Month
  } else if (dateRange === dates[4]) {
    startDate = dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = dayjs()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  return {
    startDate,
    endDate
  };
}

export const codeRepeater = (n) => {
  const repeatArray = [];
  for (let i = 0; i < n; i++) {
    repeatArray.push(i);
  }
  return repeatArray;
};

export const checkEmojiExist = (text) => {
  const re = new RegExp(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
  );
  return re.test(text);
};

export const reasonParser = (reason, classes) => {
  if (reason == null) return "";
  let nonCustomEmojiStr = "";
  let parsedReason = [];
  const possibleEmojis = reason.split("^");
  for (const possibleEmoji of possibleEmojis) {
    if (
      possibleEmoji &&
      containsUrl(possibleEmoji) &&
      possibleEmoji.indexOf("|") > -1
    ) {
      const customEmojiEle = customEmoji(
        possibleEmoji.split("|")[0],
        possibleEmoji.split("|")[1],
        classes
      );

      nonCustomEmojiStr = nonCustomEmojiStr.slice(0, -1);
      parsedReason.push(nonCustomEmoji(nonCustomEmojiStr));
      parsedReason.push(customEmojiEle);
      nonCustomEmojiStr = "";
    } else {
      nonCustomEmojiStr += `${possibleEmoji}^`;
    }
  }
  if (nonCustomEmojiStr) {
    nonCustomEmojiStr = nonCustomEmojiStr.slice(0, -1);
    parsedReason.push(nonCustomEmoji(nonCustomEmojiStr));
  }
  return parsedReason;
};

const containsUrl = (str) => {
  return new RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
  ).test(str)
    ? true
    : false;
};

const customEmoji = (name, url, classes) => {
  return (
    <div className={classes.customEmoji}>
      <img src={url} alt={name} />
    </div>
  );
};

const nonCustomEmoji = (str) => {
  return <Emoji text={str} />;
};

export const getNameInitials = (name) => {
  const names = name.trim().split(" ");
  const namesLength = names.length;
  return namesLength > 1
    ? `${names[0].charAt(0)}${names[namesLength - 1].charAt(0)}`
    : `${names[0].charAt(0)}`;
};
export function getNumInitials(n) {
  n = Number(n);
  let ord = "th";

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = "st";
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = "nd";
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = "rd";
  }

  return ord;
}
export function getRandomColor(index) {
  return hslToHex(`hsl(${index * 137.508},90%,65%)`);
}
function hslToHex(hsl) {
  const exp = /hsl\((\d*\.?\d*),\s*([\d.]+)%,\s*([\d.]+)%\)/g;
  const t = exp.exec(hsl);
  const h = t[1];
  const s = t[2];
  let l = t[3];
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
