import Auth from "../../utils/auth";
import { auth } from "../../utils/auth/firebase";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty.call("handlerEnabled") &&
    !config.handlerEnabled
    ? false
    : true;
};
export const requestSuperAdminHandler = (request) => {
  request.headers.Authorization = `Bearer ${Auth.superAdminAccessToken()}`;
  return request
}
export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    request.headers.Authorization = `Bearer ${Auth.userAccessToken()}`;
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // DO SOMETHING
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    if (error.response.status === 401) {
      auth.currentUser.getIdToken(true).then((idToken) => {
        localStorage.setItem("admin-token", idToken);
      });
    }
  }

  return Promise.reject({ ...error });
};
